import React from "react";
import { StaticQuery, graphql } from "gatsby";

import SanityImage from "gatsby-plugin-sanity-image";
import StaticImg from "../components/static-img";

import BlockContent from "@sanity/block-content-to-react";
import Seo from "../components/seo";

import "../styles/typography.css";
// import "../styles/styles.css";


function HomePagePreview({ data }) {
  return (
    <>
      <Seo
        title="Anne Tone Færøy Bøe Gestaltterapeut"
        description="Gestaltterapi er en relasjonell terapi hvor tanken er at i møte med andre blir vi til, og det er i samspillet/relasjonen til andre vi kan lære noe mer om oss selv."
      />
      <div className="main-container">
        <span className="letter-a letter">A</span>
        <span className="letter-t letter">T</span>
        <span className="letter-f letter">F</span>
        <span className="letter-b letter">B</span>
        <header className="header">
          <div className="header-title">
            <h1 className="title">Gestaltterapi</h1>
          </div>
          <StaticImg />
          <div className="empty-box"></div>
        </header>
        <div className="container">
          {data.allSanityHome.nodes.map((node) => {
            return (
              <>
                <section className="section section-padding">
                  <div className="section-img">
                    {node._rawPageImage && (
                      <SanityImage
                        {...node._rawPageImage}
                        // tell Sanity how large to make the image (does not set any CSS)

                        // style it how you want it
                        style={{
                          width: "100%",
                          height: "100%",
                          // maxHeight: "400px",
                          objectFit: "cover",
                        }}
                        alt={node.pageImage.alt}
                      />
                    )}
                  </div>
                  <div className="wrapper">
                    <h2 className="heading">{node.title}</h2>
                    <div className="description">
                      {node._rawBody && <BlockContent blocks={node._rawBody} />}
                    </div>
                  </div>
                </section>
              </>
            );
          })}
        </div>
      </div>
      <div className="logo">
        <span className="logo-top">Anne Tone Færøy Bøe</span>
        <span className="logo-bottom">Gestaltterapeut</span>
      </div>
      <footer className="footer-gradient"></footer>
    </>
  );
}
export default function HomePageFetchData() {
  return (
    <StaticQuery
      query={graphql`
        query FetchDataPreviewQuery {
          allSanityHome(sort: { fields: order, order: ASC }) {
            nodes {
              title
              _rawBody
              _rawPageImage
              pageImage {
                alt
              }
            }
          }
        }
      `}
      render={(data) => <HomePagePreview data={data} />}
    />
  );
}
